import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { IEntity, IResult } from 'library/interface';

import useApplicationQueryService, { useBasicFilterApplicationQuery, useBasicFilterTable } from './services/ApplicationQuery';

import {  IApplicationQuery, 
  IApplicationQueryOutput, 
  IApplicationQueryParameter, IApplicationQueryReport, IColumn, ITableFilter, defaultApplicationQuery, defaultApplicationQueryOutput } from './models/ApplicationQuery';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import IEnumeration from 'features/configuration/models/Enumeration';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';
import ExpressionBox from 'components/ui/ExpressionBox';
import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterReport } from 'features/configuration/services/Report';
import { IReport } from 'features/configuration/models/Report';

type OutputValueUsage = 'value-A' | 'value-U';

export const ApplicationQueryForm: FC<IApplicationQuery> = (props: IApplicationQuery = defaultApplicationQuery) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createApplicationQuery, updateApplicationQuery, getTableFilterColumns } = useApplicationQueryService();

  const {getExternalLibraries, getExternalLibrary} = externalLibraryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
    
    async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {
      const {id, name } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            if( isFalsy(row)) return;
            const {id, name, description} = row;
            
            if( getValues().applicationQueryReports.some(r => r.reportId === id) )
              return;
            
            (refAppendApplicationQueryReports.current??emptyFunc)({ id: 0, applicationQueryId: 0, reportId: id,
                reportName: name, reportDescription: description}); 
            
            setOpenReportFilter(false);
        }
    );

  const [openTableFilter, setOpenTableFilter] = useState(false);
  const basicFilterTable = useBasicFilterTable ( 
      (event: React.MouseEvent<unknown>, row: ITableFilter) => {
          const { name, description} = row;
        
        setValue('baseTableFilter', name);
        setValue('baseTableFilterDecription', description);
        setOpenTableFilter(false);
      }
  );

  const [openColumns, setOpenColumns] = useState(false);

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          setParameterEnumeration(id, name);                                      
          setOpenEnumerationFilter(false);
      }
  );

  const setParameterEnumeration = (enumerationId: number, enumerationName: string) => {
    
    const applicationQueryParameter = getValues().applicationQueryParameters.at(applicationQueryParameterIndex);
    if(isFalsy(applicationQueryParameter)) return;

    (refUpdateApplicationQueryParameter.current??emptyFunc)(applicationQueryParameterIndex,
      {...applicationQueryParameter, enumerationId, enumerationName });
  }

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          setParameterEntity(name ?? '', description?? '');                           
          setOpenEntityFilter(false);
      }
  );
  
  const setParameterEntity = (name: string, description: string) => {
   
    const applicationQueryParameter = getValues().applicationQueryParameters.at(applicationQueryParameterIndex);
    if(isFalsy(applicationQueryParameter)) return;

    (refUpdateApplicationQueryParameter.current??emptyFunc)(applicationQueryParameterIndex,
      {...applicationQueryParameter, entityName: name, entityDescription: description });
  }
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IApplicationQuery>({defaultValues:defaultApplicationQuery});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields, append: appendOutputValues, update: updateOutputValue ,remove: removeOutputValue,  } = useFieldArray({
    name: `applicationQueryOutputs`,
    control,            
  });
  
  //const watchBusinessApplicationId = watch('businessApplicationId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationQuery>,Error,IApplicationQuery>(
      _id>0?updateApplicationQuery:createApplicationQuery, {   
        onSuccess: (data: IResult<IApplicationQuery>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationQuery',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IApplicationQuery>(['ApplicationQuery', _id], () => retrieveEntity('ApplicationQuery',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const { control: controlOutputValue, setValue: setValueOutputValue, getValues: getValuesOutputValue, 
          reset: resetOutputValue, watch: watchOutputValue } = useForm<IApplicationQueryOutput>({defaultValues:defaultApplicationQueryOutput});

    const  watchOutputValueType = watchOutputValue('type');

    const [currentLibrary, setCurrentLibrary] = useState<IExternalLibrary>(defaultExternalLibrary);
    const [currentLibraryClass, setCurrentLibraryClass] = useState<IExternalLibraryClass>(defaultExternalLibraryClass);

    const [dbmsType, setDbmsType] = useState<string>('');

    const [applicationQueryIndex, setApplicationQueryIndex] = useState<number>(-1);
    const [applicationQueryParameterIndex, setApplicationQueryParameterIndex] = useState<number>(-1);

    const [openAppQueryParamDialog, setOpenAppQueryParamDialog] = useState<boolean>(false);
 
    const getInputAdornmentQueryParameter = (row: IApplicationQueryParameter, cellId: keyof IApplicationQueryParameter)  => ({
 
      toolTip: `${t('Description')} ...`,
      icon: MoreVertIcon,
      onClickIcon: (event: any, index: number, row: IApplicationQueryParameter ) => {                
        setApplicationQueryParameterIndex(index);
       
        const {parameterName, parameterDataType } = row;
        setOpenAppQueryParamDialog(true);
      }  
   })

  const cellEditableParameter = (row: IApplicationQueryParameter, cellId: keyof IApplicationQueryParameter) => {
    return true;
  }

 const getDataTypesList = (row: IApplicationQueryParameter, cellId: keyof IApplicationQueryParameter, 
  opts: {value: string, name: string}[]) => {        

  //const {requestClassEnumerationCode} = row;
  
  return [ 
    {value: 'string', name: t('Text')}, {value: 'integer', name: t('Integer')}, {value: 'decimal', name: t('Decimal')},
    {value: 'float', name: t('Float')}, {value: 'date', name: t('Date')}, {value: 'boolean', name: t('Boolean')},
    {value: 'time', name: t('Time')}, 
  ]; // getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
}

  const [headAppicationQueryParameterCells, setHeadAppicationQueryParameterCells]  = useState<HeadCell<IApplicationQueryParameter>[]>([]);
  useEffect(() => {
    setHeadAppicationQueryParameterCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'parameterName', label : t('Name'),  display: true, type: 'string', width: 50, isEditable: cellEditableParameter,
        getInputAdornment: getInputAdornmentQueryParameter },
      {id:'parameterDataType', label : t('Type'),  display: true, type: 'string', width: 50, isEditable: cellEditableParameter, 
        getOptions: getDataTypesList },
    ]  )
  }, [t,i18n])

  const refAppendApplicationQueryParameters = useRef<(value: Partial<FieldArray<IApplicationQuery>> | Partial<FieldArray<IApplicationQuery>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateApplicationQueryParameter = useRef<(index: number,value: Partial<FieldArray<IApplicationQuery>> ) => void>(null);
  const refRemoveApplicationQueryParameter = useRef<(index: number ) => void>(null);

  const handleAddQueryParameter = (event: any) => {
    (refAppendApplicationQueryParameters.current??emptyFunc)(
      {id:0, businessApplicationQueryId:0, jobScheduleParameters: [],
        parameterName: '',  parameterDataType: 'text' });
  }

  const applicationQueryParameterRowActionIcon = ( applicationQueryParameter: IApplicationQueryParameter) : ActionIconTableRow<IApplicationQuery,IApplicationQueryParameter> => {
  
    const res: ActionIconTableRow<IApplicationQuery,IApplicationQueryParameter> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IApplicationQueryParameter) => {
        
        (refRemoveApplicationQueryParameter.current??emptyFunc)(index);            
      }
    }
    return res;
}

const [headAppicationQueryReportCells, setHeadAppicationQueryReportCells]  = useState<HeadCell<IApplicationQueryReport>[]>([]);
  useEffect(() => {
    setHeadAppicationQueryReportCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'reportName', label : t('Name'),  display: true, type: 'string', width: 50,  },
      {id:'reportDescription', label : t('Description'),  display: true, type: 'string', width: 50,  },
    ]  )
  }, [t,i18n])

  const refAppendApplicationQueryReports = useRef<(value: Partial<FieldArray<IApplicationQuery>> | Partial<FieldArray<IApplicationQuery>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateApplicationQueryReport = useRef<(index: number,value: Partial<FieldArray<IApplicationQuery>> ) => void>(null);
  const refRemoveApplicationQueryReport = useRef<(index: number ) => void>(null);

  const handleAddQueryReport = (event: any) => {
    setOpenReportFilter(true);
  }

  const applicationQueryReportRowActionIcon = ( applicationQueryReport: IApplicationQueryReport) : ActionIconTableRow<IApplicationQuery,IApplicationQueryReport> => {
  
    const res: ActionIconTableRow<IApplicationQuery,IApplicationQueryReport> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IApplicationQueryReport) => {
        
        (refRemoveApplicationQueryReport.current??emptyFunc)(index);            
      }
    }
    return res;
}

const [outputValueUsage, setOutputValueUsage] = useState<OutputValueUsage>('value-A');
  
const [outputValueIndex, setOutputValueIndex] = useState<number>(-1);
const [openOutputValueDialog, setOpenOutputValueDialog] = useState(false);
const handleClickAddOutputValue = (event: any) => {
    
  setOutputValueUsage('value-A');

  setValueOutputValue('alias', '');
  setValueOutputValue('outputExpression', '');
  // setValueExtension('description', '');
  // setValueExtension('extensionTypeId', 0);
  // setValueExtension('extensionTypeName', '');
  // setValueExtension('extensionTypeType', 'text');
  // setValueExtension('extensionTypeBaseType', 'string');
  
  setOpenOutputValueDialog(true);
}

const handleClickEditOutputValue = (index: number) => {

  const applicationQueryOutput = getValues().applicationQueryOutputs.at(index);
  if(isFalsy(applicationQueryOutput)) return;

  resetOutputValue({...applicationQueryOutput!});

  setOutputValueUsage('value-U');
  setOutputValueIndex(index);  
  
  setOpenOutputValueDialog(true);
}

const handleOkOutputValue =  (event : MouseEvent<HTMLButtonElement>) => {

  const { alias, type, outputExpression, aggregationFunction } = getValuesOutputValue();
  if(isFalsy(alias.trim()))
    return;

  if(outputValueUsage === 'value-A') {
    if(getValues().applicationQueryOutputs.some(o => o.alias === alias) )
      return;

    appendOutputValues({...defaultApplicationQueryOutput, alias, type, outputExpression, aggregationFunction });
  } else if(outputValueUsage === 'value-U') {
    const applicationQueryOutput = getValues().applicationQueryOutputs.at(outputValueIndex);
    if(isFalsy(applicationQueryOutput))
      return;  

    updateOutputValue(outputValueIndex, {...applicationQueryOutput, alias, type, outputExpression, aggregationFunction } );
  }
  

  setOpenOutputValueDialog(false);
}

const handleClickSearchEnumeration = (event: any) => {
  setOpenEnumerationFilter(true);
}

const handleRemoveEnumeration = (event: any) => {
  setParameterEnumeration(0, '')
}

const handleClickSearchEntity = (event: any) => {
  setOpenEntityFilter(true);
}

const handleRemoveEntity = (event: any) => {
  setParameterEntity('', '')
}

const handleClickOpenTableFilter = (event: any) => {        
  setOpenTableFilter(true);
}

const [columns, setColumns] = useState<IColumn[]>([]);
const handleClickOpenTableFilterColumns = async (event: any) => {      
  const arr = await getTableFilterColumns(getValues().baseTableFilter);
  setColumns(arr);
  setOpenColumns(true);
}


  useEffect( () => {              
    setCurrentFormNameAtom(t('Query'));  
    setCurrentBasicTextFilterProps(basicFilterApplicationQuery);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    if(_id > 0)
      retrieveData('ApplicationQuery',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultApplicationQuery);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
  
  if(!checkEntitySaveAuthorization('ApplicationQuery', _id)){
    setIsSaveLoading(false);
    return;
  }
    
  const data = getValues(); 
  if(data.name.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  if(data.applicationQueryParameters.some( x => x.parameterName.trim() === '' || x.parameterDataType.trim() === '' )) {
    enqueueSnackbar( t('All parameters should have name and type'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
    setIsSaveLoading(false);
    return;
  }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('ApplicationQuery', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} 
                                  inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                  {...register('name')} />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Active ?')}`}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} /> 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                              inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                              {...register('description')} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(45% - 8px)'}} id="baseTableFilter" label={t('Table filter')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  {...register('baseTableFilter')} 
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenTableFilter}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} /> 
                                { openTableFilter && <FormDialog open={openTableFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Table filter')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenTableFilter(false);}} onOk={()=> {setOpenTableFilter(false);}}  >
                                      <BasicTextFilterForm<ITableFilter> {...basicFilterTable } />
                              </FormDialog> }
                              <TextField sx={{width:'calc(55% - 8px)'}} id="baseTableFilterDecription" label={`${t('Table filter')} : ${t('Description')}`} 
                                  {...register('baseTableFilterDecription')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">  
                                        { isFalsy(getValues().baseTableFilter)? null:
                                            <IconButton color="primary" onClick={handleClickOpenTableFilterColumns}>
                                              <TableRowsIcon />
                                            </IconButton>
                                        }                                                                                              
                                    </InputAdornment>
                                  )
                                }} />
                                { openColumns && <FormDialog open={openColumns} maxWidth='md'
                                okText={t('OK')} cancelText='' title={t('Feature filter')} onCancel={()=> {setOpenColumns(false);}} 
                                onClose={()=> {setOpenColumns(false);}} onOk={() => {}}  >
                                    <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }} >
                                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                                        <EnhancedTable<IColumn> 
                                            rows={columns} 
                                            headCells={[            
                                              {id:'name', label : t('Name'),  display: false, type: 'string',width: 35, },
                                              {id:'description', label : t('Name'),  display: true, type: 'string', width: 35,  },
                                              {id:'dataType', label : t('Type'),  display: true, type: 'string', width: 20,  },
                                              {id:'length', label : t('Length'),  display: true, type: 'numeric', width: 10,  },
                                            ]} 
                                            title={t('Column of table (alias)')} objKey={'name'} 
                                            
                                            onRowSelected={undefined} onRowDoubleClick={undefined} 
                                            onRowCheckedSelectChange={undefined} toolbarActions={undefined} order='asc' 
                                            orderBy={'name'}
                                        />
                                      </Box>
                                    </Stack>
                                </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={`${t('Query')} - (Select)`} 
                                {...register('querySelect')} multiline={true} rows={3}/>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={`${t('Query')} - (Group By)`} 
                                {...register('queryGroup')} multiline={true} rows={3}/>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={`${t('Query')} - (Order By)`} 
                                {...register('queryOrder')} multiline={true} rows={3}/>
                            </Box>
                            
                            <Box sx={{ mt: 2, width: '100%' }} >
                              <Button>
                                {`${t('Add Output value')}`}
                                <Box sx={{ ...justifyCenter, ml: 1 }}>
                                  <MdOutlineAdd size={24} onClick={handleClickAddOutputValue} />
                                </Box>
                              </Button>
                              { openOutputValueDialog && <FormDialog open={openOutputValueDialog} maxWidth='sm' height='50vh'
                                    okText={t('OK')} cancelText='' title={` Val. - `} onCancel={()=> {}} 
                                    onClose={()=> {setOpenOutputValueDialog(false);}} onOk={handleOkOutputValue}  >
                                        <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }} key={` param ${getValues().name}`} >
                                          <Box sx={{ mt: 1, width: '100%' }} >
                                            <Controller
                                                key={`query-param-query-description`}
                                                render={({ field }) => <TextField label={t('Alias')} 
                                                    {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                  sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                                  name={`alias`}                      
                                                control={controlOutputValue}
                                              />
                                          </Box>
                                          
                                          <ExpressionBox<IApplicationQueryOutput> key={`expression : ${getValuesOutputValue().outputExpression}`}
                                                control={controlOutputValue} setValue={setValueOutputValue} getValues={getValuesOutputValue}
                                                fieldExpressions={[
                                                  {entityName: 'BaseEntity', returnType: 'object', textRowCount: 2,
                                                    path: `outputExpression`, 
                                                    label: `${t('Expression')} - ...`, 
                                                    expression: getValuesOutputValue().outputExpression },
                                                  ]}
                                                />
                                          <Box sx={{ mt: 1, width: '100%' }} >
                                            <Controller 
                                              name={`type`}
                                              control={controlOutputValue}
                                              render={ ({field: {onChange, value}}) => (
                                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="type"
                                                  label={t('Type')} inputProps={ {readOnly: false}}>
                                                      <MenuItem value='base'>{t('Base')}</MenuItem>         
                                                      <MenuItem value='aggregation'>{t('Aggregation')}</MenuItem>                                            
                                                </TextField>
                                              )}
                                            />
                                            <Controller key={`agg ${watchOutputValueType}`} 
                                              name={`aggregationFunction`}
                                              control={controlOutputValue}
                                              render={ ({field: {onChange, value}}) => (
                                                <TextField select onChange={onChange} value={value} id="type"
                                                  sx={{width:'calc(50% - 8px)', display: (watchOutputValueType === 'base'? 'none': 'display') }} 
                                                  label={t('Type')} inputProps={ {readOnly: watchOutputValueType === 'base'}}>
                                                      <MenuItem value='count'>{`${t('Count')} (Count)`}</MenuItem>         
                                                      <MenuItem value='sum'>{t('Sum')}</MenuItem>                                            
                                                      <MenuItem value='min'>{t('Min')}</MenuItem>                                            
                                                      <MenuItem value='max'>{t('Max')}</MenuItem>                                            
                                                      <MenuItem value='avg'>{t('Avg')}</MenuItem>                                            
                                                </TextField>
                                              )}
                                            />
                                          </Box>
                                          
                                        </Stack>
                                </FormDialog> }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              {                              
                                  getValues().applicationQueryOutputs.map( ( {alias, outputExpression, type, aggregationFunction } ,idx) => 
                                    ( <Chip sx={{ ml: 1, mr:1, mb: 1 }} 
                                        icon={ (type === 'base') ? <CheckBoxOutlineBlankIcon />: 
                                          (aggregationFunction === 'count') ? <RepeatOneIcon /> : 
                                          (aggregationFunction === 'sum') ? <FunctionsIcon /> :   
                                          (aggregationFunction === 'min') ? <VerticalAlignBottomIcon /> :
                                          (aggregationFunction === 'max') ? <VerticalAlignTopIcon /> :
                                          (aggregationFunction === 'avg') ? <FormatLineSpacingIcon /> :<InsightsIcon />}
                                        key={`${idx} - ${alias}`} label={`${alias} : ${outputExpression} `} color="primary" variant="outlined"
                                        onDelete={() => {removeOutputValue(idx)}} 
                                        onClick={() => handleClickEditOutputValue(idx)}/>
                                        ))
                                }  
                            </Box>
                            <Box sx={{ mt: 4, width: '100%' }} >
                              <Controller 
                                  name={`methodName`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="methodName"
                                      label={t('Data preparation method')} inputProps={ {readOnly: false}}>                                
                                      {currentLibraryClass.externalLibraryClassMethods.map( 
                                          (x,idx) => <MenuItem key={x.methodName} value={x.methodName}>{x.methodName}</MenuItem> )
                                        }
                                    </TextField>
                                  )}
                              />
                            </Box>
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={6} component={Paper} >
                        <Stack flexDirection='column'>      
                            
                              
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={`${t('Query')} - ${t('Filter')}`} 
                                {...register('queryFilter')} multiline={true} rows={3}/>
                            </Box>                   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IApplicationQuery,IApplicationQueryParameter,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headAppicationQueryParameterCells} rowsPathName={`applicationQueryParameters` }
                                title={t('Parameters')} rowActionIcon={applicationQueryParameterRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendApplicationQueryParameters as MutableRefObject<(value: Partial<FieldArray<IApplicationQuery>> | Partial<FieldArray<IApplicationQuery>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateApplicationQueryParameter as MutableRefObject<(index: number,value: Partial<FieldArray<IApplicationQuery>>) => void>}
                                refRemove={refRemoveApplicationQueryParameter as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddQueryParameter ,icon: AddCircleIcon,  },
                                
                                ]}
                              />                         
                            </Box>
                            <Box sx={{ mt: 5, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t('Reports use for export data')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} >
                              <ArrayFieldTableEx<IApplicationQuery,IApplicationQueryReport,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headAppicationQueryReportCells} rowsPathName={`applicationQueryReports` }
                                title={t('Reports')} rowActionIcon={applicationQueryReportRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendApplicationQueryReports as MutableRefObject<(value: Partial<FieldArray<IApplicationQuery>> | Partial<FieldArray<IApplicationQuery>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateApplicationQueryReport as MutableRefObject<(index: number,value: Partial<FieldArray<IApplicationQuery>>) => void>}
                                refRemove={refRemoveApplicationQueryReport as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddQueryReport ,icon: AddCircleIcon,  },
                                
                                ]}
                              />                         
                            </Box>
                            { openReportFilter && <FormDialog open={openReportFilter} maxWidth='md'
                                  okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                      <BasicTextFilterForm<IReport> {...basicFilterReport } />
                              </FormDialog> }
                            { openAppQueryParamDialog &&                                   
                                  <FormDialog open={openAppQueryParamDialog} maxWidth='sm' height='35vh'
                                    okText={t('OK')} cancelText='' title={` Param - ${getValues().applicationQueryParameters[applicationQueryParameterIndex].parameterName}`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenAppQueryParamDialog(false);}} onOk={()=> {setOpenAppQueryParamDialog(false);}}  >
                                        <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }} key={` param ${getValues().name}`} >
                                          <Box sx={{ mt: 1, width: '100%' }}>
                                            <Controller
                                              key={`query-param-query-description`}
                                              render={({ field }) => <TextField label={t('Description')} 
                                                  {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                                name={`applicationQueryParameters.${applicationQueryParameterIndex}.description`}                      
                                              control={control}
                                            />
                                          </Box>
                                          { getValues().applicationQueryParameters[applicationQueryParameterIndex].parameterDataType === 'string' &&
                                          <Box sx={{ mt: 1, width: '100%' }} key={` enumeration ${getValues().applicationQueryParameters[applicationQueryParameterIndex].enumerationId}`}>
                                            <Controller
                                              key={`query-param-query-enumeration`}
                                              render={({ field }) => <TextField label={t('Enumeration')} 
                                                  {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                sx={{width:`calc(100% - 8px)`, textTransform: 'none'}} 
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">      
                                                      {
                                                        ( (getValues().applicationQueryParameters[applicationQueryParameterIndex].enumerationId || 0) <= 0) ?
                                                        <IconButton color="primary" onClick={handleClickSearchEnumeration}>
                                                          <ArrowDropDownCircleIcon />
                                                        </IconButton> :
                                                        <IconButton color="primary" onClick={handleRemoveEnumeration}>
                                                          <RemoveCircleIcon />
                                                        </IconButton>
                                                      }                                                                                              
                                                  </InputAdornment>
                                                )
                                              }} />} 
                                                name={`applicationQueryParameters.${applicationQueryParameterIndex}.enumerationName`}                      
                                              control={control}                                              
                                            />
                                            { openEnumerationFilter && <FormDialog open={openEnumerationFilter} maxWidth='md'
                                                  okText='' cancelText='' title={t('Enumeration filter')} onCancel={()=> {}} 
                                                  onClose={()=> {setOpenEnumerationFilter(false);}} onOk={()=> {setOpenEnumerationFilter(false);}}  >
                                                      <BasicTextFilterForm<IEnumeration> {...basicFilterEnumeration } />
                                              </FormDialog> }
                                          </Box> }
                                          { getValues().applicationQueryParameters[applicationQueryParameterIndex].parameterDataType === 'long' &&
                                            <Box sx={{ mt: 1, width: '100%' }} key={` entity ${getValues().applicationQueryParameters[applicationQueryParameterIndex].entityName}`}>
                                              <Controller
                                                key={`query-param-query-entity`}
                                                render={({ field }) => <TextField label={t('Entity')} 
                                                    {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment position="end">      
                                                          { isFalsy(getValues().applicationQueryParameters[applicationQueryParameterIndex].entityName) ?                                      
                                                            <IconButton color="primary" onClick={handleClickSearchEntity}>
                                                              <ArrowDropDownCircleIcon />
                                                            </IconButton> :
                                                            <IconButton color="primary" onClick={handleRemoveEntity}>
                                                              <RemoveCircleIcon />
                                                            </IconButton>
                                                          }                                                                                            
                                                      </InputAdornment>
                                                    )
                                                  }}
                                                  sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                                                  name={`applicationQueryParameters.${applicationQueryParameterIndex}.entityDescription`}                      
                                                control={control}
                                              />
                                            { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                                  okText='' cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                                  onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {}}  >
                                                      <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                              </FormDialog> }
                                          </Box> }
                                          { getValues().applicationQueryParameters[applicationQueryParameterIndex].parameterDataType === 'integer' &&
                                          <Box sx={{ mt: 1, width: '100%' }} key={` period ${getValues().applicationQueryParameters[applicationQueryParameterIndex].isPeriod}`}>
                                            <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                              key={`query-param-query-period`}
                                              label={t('Period ?')}
                                              control={
                                              <Controller
                                                  name={`applicationQueryParameters.${applicationQueryParameterIndex}.isPeriod`} 
                                                  control={control}
                                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                              />} />
                                          </Box> }
                                          

                                        </Stack>
                                    </FormDialog> } 
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
