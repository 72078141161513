import { IBilling } from "features/finance/models/Billing";
import { DataType } from "features/configuration/models/ExtensionType";
import { ITellerOperation } from "features/finance/models/TellerOperation";


export interface IRegistration {
    id: number,
    schoolYearClassId: number,
    personId: number,
    registrationCode: string,
    status: string,
    statusDate: Date,
    effectiveDate: Date,
    validationDate: Date,
    arriveOrGoesByBus: boolean,

    isRepeater: boolean,
    studentFrom: string,

    busDestinationId: number,
    isArrival: boolean,
    isDeparture: boolean,
    busDestinationName: string,

    takeBrunch: boolean,
    order1: number,
    order2: number,
    orderX: number,
    portable1: string,
    portable2: string,
    email1: string,
    email2: string,

    numberOfAbsences: number,
    numberOfDelays: number,

    billings: IBilling[],
    tellerOperations: ITellerOperation[],
    registrationExtensions: IRegistrationExtension[],

    base64Picture: string,
    
    firstName: string,
    lastName: string,
    particularSign: string,
    birthDate: Date,
    birthPlace: string,
    civility: string,
    gender: string,

    studentCode: string,

    defaultLanguage: string,
    nationality: string,
    identificationType: string,
    identificationNumber: string,
    identificationDelivrancePlace: string,
    identificationStartDate: Date,
    identificationEndDate: Date,

    studentName: string,

    schoolYearClassName: string,
    schoolYearName: string,

    schoolYearId: number,
    studyType: string,

    studyLanguage: string,
    schoolYearClassTemplateId: number,
    classTemplateId: number,

    fatherName: string,
    motherName: string,
    tutorName: string,

    amount: number,
    dueAmount: number,
    unpaidAmount: number,
    amountPaid: number,

    schoolFeesAmount: number,
    schoolFeesAmountPaid: number,
    schoolFeesUnpaidAmount: number,
    schoolFeesDueAmount: number,

    newBillings: IBilling[],
    newArticleBillings: IBilling[],
    newTransportBillings: IBilling[],


    isMarkExcluded: boolean,

    subjects: ISubjectMark[],
    mark: number,
    rank: number,

    currentMarkStatus: string,
    currentAverageMark: number,
    currentRank: number,

    registrationMaterials: IRegistrationMaterial[],

    // this is for UI purpose
    initialSchoolYearClassId: number
  }

  export interface IRegistrationExtension {
    id: number,
    registrationId: number,
    schoolYearRegistrationExtensionId: number,

    extensionValueId: number,
    extensionDescription: string,
  
  
    type: DataType,
    enumerationCode: string,
  
    value: any
  }

  export interface IRegistrationSheet {
    id: number,
    registrationId: number,
    SchoolYearPeriodId: number,

    isCancelled: boolean,
    cancellationDate: Date,
    cancellationPurpose: string,
    type: 'disciplinary' | 'absence' | 'observation',

    absenceType: string,
    absencePurpose: string,
    absencePurposeDescription: string,
    justifedAbsence: boolean,
    absenceStartDate: Date,
    absenceEndDate: Date,
    absenceDurationUnit: string,
    absenceDurationValue: number,

    disciplinaryType: string,
    disciplinaryEventSummary: string,
    disciplinaryEventDescription: string,
    disciplinaryEventDate: Date,

    observationPurpose: string,
    observationDescription: string,
    observationDate: Date
  }

  export interface IRegistrationSanction {
    id: number,
    registrationSheetId: number,
    sanctionCategoryCode: string,
    description: string,
    
    isCancelled: boolean,
    cancellationPurpose: string,
    type: string,

    sanctionDate: Date,    
  }

  export interface IRegistrationComplaint {
    id: number,
    registrationId: number,
    complaintCategoryCode: string,
    
    summary: string,
    description: string,
    complaintDate: Date,
    assignedTeacherId: number,
    resolutionDate: Date,    
    resolutionDescription: string,
    status: string,
  }


  export interface ISubjectMark {
        
    schoolYearTemplateSubjectId: number,

    subject: string,
    shortName: string,
    longName: string,

    firstName: string,
    lastName: string,

    schoolYearPeriodExamName: string,

    mark1: number,
    mark2: number,
    mark3: number,
    mark4: number,
    mark5: number,
    mark6: number,
    mark7: number,
    mark8: number,
    mark9: number,
    mark10: number,
    mark11: number,
    mark12: number,
    mark13: number,
    mark14: number,
    mark15: number,
    mark16: number,
    mark17: number,
    mark18: number,
    mark19: number,
    mark20: number,

    marks: IMark[],

    mark: number,
    maxMark: number,

    reportCardMark: number,
    coefficient: number,
}

export interface IMark {
  schoolYearPeriodExamName : string,

  examValue: number,
  examMaxValue: number,

  subjectValue: number, // Value reported in the base of max mark of subject
  subjectMaxValue: number, // Max mark for the subject
  subjectValueWeight: number, // The contribution of this mark in the final mark

  reportCardValue: number,
  reportCardMaxValue: number,
  reportCardValueWeight: number,

  coefficient: number,
  weight: number,
}

export interface IRegistrationMaterial {
  id: number,
  registrationId: number,
  schoolMaterialCode: string,
  
  status: string,
  statusDate: Date,
  statusPurpose: string,
}

  export const defaultRegistration : IRegistration = {
    id: 0,
    schoolYearClassId: 0,
    personId: 0,
    registrationCode: '',
    status: '95',
    statusDate: new Date(),
    effectiveDate: new Date(),
    validationDate: new Date(),
    arriveOrGoesByBus: false,

    isRepeater: false,
    studentFrom: '',

    busDestinationId: 0,
    isArrival: false,
    isDeparture: false,
    busDestinationName: '',

    takeBrunch: false,
    order1: 0,
    order2: 0,
    orderX: 0,
    portable1: '',
    portable2: '',
    email1: '',
    email2: '',

    numberOfAbsences: 0,
    numberOfDelays: 0,

    billings: [],
    tellerOperations: [],
    registrationExtensions: [],

    base64Picture: '',

    firstName: '',
    lastName: '',
    particularSign: '',
    birthDate: new Date(),
    birthPlace: '',
    civility: '',
    gender: '',

    studentCode: '',

    defaultLanguage: '',
    nationality: '',
    identificationType: '',
    identificationNumber: '',
    identificationDelivrancePlace: '',
    identificationStartDate: new Date(),
    identificationEndDate: new Date(),

    studentName: '',

    schoolYearClassName: '',
    schoolYearName: '',

    schoolYearId: 0,
    studyType: '',

    studyLanguage: '',
    schoolYearClassTemplateId: 0,
    classTemplateId: 0,

    fatherName: '',
    motherName: '',
    tutorName: '',

    amount: 0,
    dueAmount: 0,
    unpaidAmount: 0,
    amountPaid: 0,

    schoolFeesAmount: 0,
    schoolFeesAmountPaid: 0,
    schoolFeesUnpaidAmount: 0,
    schoolFeesDueAmount: 0,

    newBillings: [],
    newArticleBillings: [],
    newTransportBillings: [],

    isMarkExcluded: false,
    
    subjects: [],

    mark: 0,
    rank: 0,

    currentMarkStatus: '',
    currentAverageMark: 0,
    currentRank: 0,

    registrationMaterials: [],
    
    // this is for UI purpose
    initialSchoolYearClassId: 0
  }

  export const defaultRegistrationSheet : IRegistrationSheet = {
    id: 0,
    registrationId: 0,
    SchoolYearPeriodId: 0,
    isCancelled: false,
    cancellationDate: new Date(),
    cancellationPurpose: '',

    type: 'disciplinary',
    absenceType: '',
    absencePurpose: '',
    absencePurposeDescription: '',
    justifedAbsence: false,
    absenceStartDate: new Date(),
    absenceEndDate: new Date(),
    absenceDurationUnit: '',
    absenceDurationValue: 0,

    disciplinaryType: '',
    disciplinaryEventSummary: '',
    disciplinaryEventDescription: '',
    disciplinaryEventDate: new Date(),

    observationPurpose: '',
    observationDescription: '',
    observationDate: new Date()
  }

  export const defaultRegistrationSanction : IRegistrationSanction = {
    id: 0,
   
    registrationSheetId: 0,
    sanctionCategoryCode: '',
    description: '',
    isCancelled: false,
    cancellationPurpose: '',
    type: '',

    sanctionDate: new Date(),
  }

  export const defaultRegistrationComplaint : IRegistrationComplaint = {
    id: 0,   
    registrationId: 0,

    complaintCategoryCode: '',
    summary: '',
    description: '',
    complaintDate: new Date(),
    assignedTeacherId: 0,
    resolutionDate: new Date(),    
    resolutionDescription: '',
    status: ''
  }



  export interface IRegistrationSearch {
    schoolYearId: number,
    studyType: string,
    studyLanguage: string,

    schoolYearClassName: string,

    firstName: string,
    lastName: string,
        
  }